import Layout from '../components/layouts/en';
import Privacy from '../components/Privacy';
import React from 'react';

const IndexPage = ({ location, ...rest }) => (
  <Layout location={location}>
    <Privacy />
  </Layout>
);

export default IndexPage;
