import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from './_layout';
import { addLocaleData } from 'react-intl';

import messages from '../../data/messages/en';
import en from 'react-intl/locale-data/en';
import 'intl/locale-data/jsonp/en';

addLocaleData(en);

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => <Layout {...props} data={data} i18nMessages={messages} />}
    />
  );
};
