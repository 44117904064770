module.exports = {
  'main.brandName': 'HERO SNATCH',
  'main.youCanDoIt': 'New Home for your Workouts',
  'main.trackIt': 'Create WODs, Track Records and connect with Community',
  'main.tryIt': 'Start Now',
  'metric.results': 'Results Submitted',
  'metric.wods': 'Workouts Created',
  'metric.modality': 'Users Joined',
  'features.tracking.title': 'Do you remember your last score?',
  'features.tracking.body': 'Herosnatch does it for you',
  'features.modality.title': 'Make your progress clear',
  'features.modality.body':
    'Through beautiful charts and calendar, we will help you to better understand your progress',
  'features.scheme.title': 'We are Community',
  'features.scheme.body':
    'Trusted by 1000+ of athletes supporting each other on the journey to excellence',
  'joinus.title': `Join us and become
  the part of our family!`,
  'compatibility.title': `HeroSnatch works with
  all browsers and
  devices`,
  'compatibility.text1':
    'HeroSnatch works with all browsers and devices. The application gives you insights into your functional training process by providing super accurate and delicate information.',
  'compatibility.text2':
    ' Application allows you to create your workouts, follow workouts of your friends, compete with each other, see your statistics whether you spend more time on a specific type of exercises instead of focusing on your general level of preparation and other great things.',
  'compatibility.text3': 'Join us and become the part of our family!',
  'compatibility.ios': 'Download on ios',
  'compatibility.android': 'Download on android',
  'news.title': 'Blog',
  'news.cardTitle': 'HeroSnatch live',
  'news.cardContent1': `HeroSnatch is an incredible way to keep fighting with yourself during workouts. When I track my result, I always want to beat myself.\n
   It’s like a fight with the shadow!`,
  'news.cardContent2': `You get the result when you track and control it. Thanks to HeroSnatch, I achieve my goals and see the progress in a convenient form. It's nice to see how the graph of my power and endurance grows up. And even nicer to share it with my friends :)`,
  'news.cardContent3': `I found HeroSnatch workouts database extremely useful for my training routines. It always gives me fresh ideas for new WODs. Beside that HeroSnatch pushes me to train more gymnastics by showing statistics of movements. My gymnastics got so much better!`,
  'navbar.athlete': 'Athlete',
  'navbar.coach': 'Coach',
  'navbar.blog': 'Blog',
  'footer.title': 'Hurry up and get the early bird access!',
  'footer.privacy': 'Privacy Policy',
  'footer.terms': 'Terms of Use',
  'testimonials.title': 'Testimonials',
  'testimonials.author1': 'Svyat Smolnik',
  'testimonials.author2': 'Maria Holub',
  'testimonials.author3': 'Oleg Moroz',
  'testimonials.role1': 'Competing Athlete',
  'testimonials.role2': 'Captain Adidas Runners Kyiv',
  'testimonials.role3': 'UX Architect at Digicode',
  'feedback.subtitle': 'We will notify you once HeroSnatch will be LIVE!',
  'feedback.title': 'Be one of the first athletes!',
  'feedback.btn': 'Send',
  'feedback.thanks': 'We will contact you shortly!',
  'feedback.textarea': 'Tell us your feedback :)',
  'blog.title': 'Blog',
  'blog.subtitle': 'Last news',
  'blog.news': 'News',
  'blog.events': 'Events',
  'blog.nutrition': 'Nutrition',
  'blog.training': 'Workouts',
  'blog.date': '6 April 2018',
  'blog.title1': 'Gym or Crossfit?',
  'blog.title2': 'How to eat those who are engaged in Crossfit',
  'blog.content1':
    'Невероятный опыт, пользуюсь неделю – как буд-то пересел с самоката на Теслу. Очень понравилось удобство ведения статистики',
  'blog.content2': 'Невероятный опыт, пользуюсь неделю – как буд-то пересел с самоката на Теслу.',
  'article.title': 'HeroSnatch in life',
  'article.date': '06 April 2018',
  'article.share': 'Share:',
  'article.category': 'Categories',
  'article.articles': 'Other articles',
  'pricing.title': 'PRICING',
  'pricing.basic': 'BASIC',
  'pricing.athlete': 'ATHLETE',
  'pricing.coach': 'COACH',
  'pricing.perMonth': 'Per month',
  'pricing.perAthleteMonth': 'Per athlete / month',
  'pricing.free': 'FREE',
  'pricing.getStarted': 'GET STARTED',
  'pricing.contactUs': 'CONTACT US',
  'pricing.basicFor': 'Free for all users',
  'pricing.athleteFor': 'For athletes and motivated people',
  'pricing.coachFor': 'For coaches and box owners',
  'pricing.priceBasic': 'FREE',
  'pricing.priceAthlete': '4.99$',
  'pricing.priceCoach': '1.5$',
  'pricing.basicDescription':
    "No more excuses, it's time to start tracking! A basic Kilomodo account provides you with the essentials to start tracking workouts and follow along with other athletes at a gym or on a team.",
  'pricing.athleteDescription':
    "No more excuses, it's time to start tracking! A basic Kilomodo account provides you with the essentials to start tracking workouts and follow along with other athletes at a gym or on a team.",
  'pricing.coachDescription':
    "No more excuses, it's time to start tracking! A basic Kilomodo account provides you with the essentials to start tracking workouts and follow along with other athletes at a gym or on a team.",
  'pricing.feature.unlimited': 'Unlimited',
  'pricing.feature.unlimitedAccess': 'Unlimited Access',
  'pricing.feature.limitedAccess': 'Limited Access',
  'pricing.feature.unlimitedHistory': 'Unlimited History',
  'pricing.feature.weeksHistory': 'Weeks History',
  'pricing.feature.workoutsTracking': 'Workouts Tracking',
  'pricing.feature.workoutsTrackingTooltip': 'Workouts Tracking Tooltip',
  'pricing.feature.workoutLibrary': 'Workout Library',
  'pricing.feature.workoutLibraryTooltip': 'Workout Library Tooltip',
  'pricing.feature.workoutBuilder': 'Workout Builder',
  'pricing.feature.weightCalculator': 'Weight Calculator',
  'pricing.feature.weeklyReporting': 'Weekly Reporting',
  'pricing.feature.personalRecords': 'Personal Records',
  'pricing.feature.personalWorkoutLibrary': 'Personal Workout Library',
  'pricing.feature.personalWorkoutLibraryTooltip': 'Personal Workout Library Tooltip',
  'pricing.feature.activityFeed': 'Community Activity Feed',
  'pricing.feature.activityFeedTooltip': 'Community Activity Feed Tooltip',
  'pricing.feature.publicWorkouts': 'Public Workouts',
  'pricing.feature.publicWorkoutsTooltip': 'Public Workouts Tooltip',
  'pricing.feature.privateWorkouts': 'Private Workouts',
  'pricing.feature.privateScoring': 'Private Scoring',
  'pricing.feature.privateScoringTooltip': 'Private Scoring Tooltip',
  'pricing.feature.resultAnalysis': 'Score Analysis & Stats',
  'pricing.feature.resultAnalysisTooltip': 'Results Analysis & Stats Tooltip',
  'pricing.feature.resultAnalysisBasic': '2 Weeks',
  'pricing.feature.programTracks': 'Program Tracks',
  'pricing.feature.programTracksTooltip': 'Program Tracks Tooltip',
  'pricing.feature.programTracksAthlete': '1 Private Track',
  'pricing.feature.programTracksCoach': '5 Programs',
  'pricing.feature.programBuilder': 'Program Builder Access',
  'pricing.feature.membershipManagement': 'Manage Individuals & Teams',
  'pricing.feature.membershipManagementTooltip': 'Membership Management Tooltip',
  'pricing.feature.memberNotifications': 'Members Notifications',
  'pricing.feature.allFromPlanBasic': 'Everything from Basic plan +',
  'pricing.feature.allFromPlanAthlete': 'Everything from Athlete plan +'
};
