import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './index.module.sass';

const propTypes = {
  intl: intlShape.isRequired,
  path: PropTypes.string
};

class Footer extends React.Component {
  trackAnalytics() {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get('ref');
    window.analytics.track('sign_up_click', {
      referrer: referrer,
      organic: !referrer
    });
  }

  render() {
    const { intl, path = '' } = this.props;
    const currentLang = intl.locale === 'en' ? 'Russian' : 'Английский';
    const changedLangUrl = intl.locale === 'en' ? `/ru/${path}` : `/en/${path}`;

    return (
      <section className={styles.footer}>
        <div className={styles.footer__outer}>
          <div className="hs-container">
            <h2 className={styles.footer__title}>
              <FormattedMessage id="footer.title" />
            </h2>
            <div className={styles.footer__actions} onClick={this.trackAnalytics}>
              <a
                href="https://app.herosnatch.com/onboarding"
                onClick={this.trackAnalytics}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button type="button" className="hs-button green">
                  <FormattedMessage id="main.tryIt" />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className={cx('hs-container', styles.footer__links)}>
          <ul>
            <li>
              <Link to="/privacy">
                <FormattedMessage id="footer.privacy" />
              </Link>
            </li>
            <li>
              <Link to="/terms">
                <FormattedMessage id="footer.terms" />
              </Link>
            </li>
            <li>
              <Link to={changedLangUrl}>{currentLang}</Link>
            </li>
            <li className={styles.space} />
            <li className={cx('navbar-end', styles.navigation)}>
              {/* <Link to={'/athlete'} className={cx('navbar-item', styles.link)}>
                  <FormattedMessage id="navbar.athlete" />
                </Link>
                <Link to={'/coach'} className={cx('navbar-item', styles.link)}>
                  <FormattedMessage id="navbar.coach" />
                </Link> */}
              <Link to={'/blog'} className={cx('navbar-item', styles.link)}>
                <FormattedMessage id="navbar.blog" />
              </Link>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/hero.snatch/"
                className={cx('navbar-item', 'instagram')}
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/HeroSnatch"
                className={cx('navbar-item', 'facebook')}
              />
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

Footer.propTypes = propTypes;

export default injectIntl(Footer);
