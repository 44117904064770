import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import Logo from '../../img/logo-hs.svg';
import React from 'react';
import cx from 'classnames';
import styles from './index.module.sass';

const Navbar = () => {
  return (
    <nav className={cx('navbar', 'is-transparent', styles.header)}>
      <div className="container">
        <div className="navbar-brand">
          <Link className={cx('navbar-item', styles.brandName)} to="/">
            <img className="navbar-logo" src={Logo} alt="" />
            <span>HEROSNATCH</span>
          </Link>
          <a className="support-email" href="mailto:info@herosnatch.com">
            info@herosnatch.com
          </a>
        </div>
        <div className="navbar-end">
          {/* <Link to={'/athlete'} className={cx('navbar-item', styles.link)}>
            <FormattedMessage id="navbar.athlete" />
          </Link>
          <Link to={'/coach'} className={cx('navbar-item', styles.link)}>
            <FormattedMessage id="navbar.coach" />
          </Link> */}
          <Link to={'/blog'} className={cx('navbar-item', styles.link)}>
            <FormattedMessage id="navbar.blog" />
          </Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/hero.snatch/"
            className={cx('navbar-item', 'instagram')}
          />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/HeroSnatch"
            className={cx('navbar-item', 'facebook')}
          />
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {};

export default Navbar;
